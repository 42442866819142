<template>
  <div class="help-document">
    <div class="title"><span>帮助文档</span><el-button type="primary" @click="$router.push('help-document/add')">新增</el-button></div>
    <table>
      <tr>
        <td>内容</td>
        <td>发布时间</td>
        <td>操作</td>
      </tr>
      <tr class="or-tr" v-for="it in list" :key="it">
        <td class="space">
          <div class="left-box">
            <img :src="it.coverImage" alt="image" v-if="it.coverImage">
          </div>
          <div class="right-box">
            <p class="top-p">{{it.title}}</p>
            <p class="under-p" :title="it.abstract">{{it.abstract}}</p>
          </div>
        </td>
        <td>{{it.publishedAt>0? returnTime(it.publishedAt): '--'}}</td>
        <td class="fn">
          <span class="text" @click="$router.push(`help-document/add?id=${it.id}`)">编辑</span>
          <span class="text" @click="publish(it)">发布</span>
          <span class="del" @click="del(it.id)">删除</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

import { getHelpDocument, delHelpDocument, helpPublish } from '@/api/tips'

export default {
  name: 'help-document',
  data () {
    return {
      list: [],
      visible: false,
      nowContent: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      const res = await getHelpDocument()
      if (res.code === 0) {
        this.list = res.data.items
      }
    },
    del (id) {
      delHelpDocument({ id }).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: '1000'
          })
          this.init()
        }
      })
    },
    publish (row) {
      if (row.content === '' || row.coverImage === '' || row.title === '' || row.endpoints.length === 0) {
        this.$notify({
          title: '警告',
          message: '内容不全, 无法发布',
          type: 'error',
          duration: '1000'
        })
      } else {
        helpPublish({ id: row.id }).then(res => {
          if (res.code === 0) {
            this.$notify({
              title: '成功',
              message: '发布成功',
              type: 'success',
              duration: '1000'
            })
            this.init()
          } else {
            this.$notify({
              title: '警告',
              message: '发布失败',
              type: 'error',
              duration: '1000'
            })
          }
        })
      }
    },
    returnTime (s) {
      const d = new Date(s * 1000)
      const YYYY = d.getFullYear()
      const MM = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
      const DD = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
      return YYYY + '.' + MM + '.' + DD
    }
  }
}
</script>

<style lang="less" scoped>
.help-document{
  width: 100%;
  .title{
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      font-size: #333333;
      font-weight: bold;
      font-size: 20px;
    }
    .el-button{
      outline: 0;
    }
    .el-button--primary{
      border-radius: 2px ;
      background-color: #3775F6;
      border-color: #3775F6;
    }
  }
  table{
    width: 100%;
    border-left: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
    margin-right: 1rem;
    tr{
      width: 100%;
      td{
        border-right: 1px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9;
      }
      td:nth-child(1){
        flex: 1;
      }
      td:nth-child(2){
        width: 110px;
      }
      td:nth-child(3){
        width: 170px;
      }
    }
    tr:nth-child(1){
      display: flex;
      td:nth-child(1){
        padding: 12px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      td:nth-child(2){
        display: flex;
        justify-content: center;
        align-items: center;
      }
      td:nth-child(3){
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .or-tr{
      height: 74px;
      display: flex;
      .space{
        display: flex;
        .left-box{
          min-width: 150px;
          max-width: 150px;
          height: 50px;
          margin-right: 10px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .right-box{
          flex: 1;
          p{
            line-height: 25px;
            height: 25px;
            margin-bottom: 0px;
          }
          .top-p{
            font-size: 14px;
            color: #555555;
            max-width: 530px;
            min-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .under-p{
            max-width: 530px;
            min-width: 200px;
            font-size: 12px;
            color: #999999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .fn{
        span{
          cursor: pointer;
          font-size: 14px;
        }
        .text{
          color: #3775F6;
        }
        .del{
          color: #F56C6C;
        }
      }
      td:nth-child(1){
        padding: 12px 20px;
      }
      td:nth-child(2){
        display: flex;
        justify-content: center;
        align-items: center;
      }
      td:nth-child(3){
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}
</style>
